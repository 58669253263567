.Logo {
    display: inline-block;
    width: 64px;
    height: 64px;
    flex: 0 0 64px;
    position: absolute;
    right: 10px;
    top: 10px;

    &:hover {
        opacity: 1;
    }

    @media (max-width: 768px) {
        top: initial;
        bottom: 25px;
    }
}