@import "~normalize.css";
@import "toastr";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";

body, html {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.App {
  position: fixed;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}