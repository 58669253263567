
.Spinner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    z-index: 10000;
    background-color: rgba(0, 0, 0, 0.2);
}